/**
 *  Each object links to a flag to determine which header features to toggle off per locale or the
 *  rollup locale of a given locale (i.e. en_CB covers all Caribbean locales)
 */
  // eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class HeaderFeatureToggles {
  /**
   * @ignore
   */
  public static SHOWSEARCH: any = ['en_CB', 'en_US', 'en_GB', 'es_US'];

  public static HIDELOYALTYBUTTONS: any = ['es_ES','en_EU', 'en_AP', 'fr'];

  //If a locale in the rollup hides login, this list can be used as an override (i.e. 'fr' would disable 'fr_CA'
  //so fr_CA is in this list to override)
  public static LOYALTYBUTTONOVERRIDE: any = ['en_US', 'en_GB', 'fr_CA'];
}
