/**
 * Roll up data used to return content
 * if input locale data does not exist in translation files
 */
 // eslint-disable-next-line @typescript-eslint/no-extraneous-class
 export class HeaderTranslations {
    /**
     * @ignore
     */
    public static DATA: any = {
      'en_US': {
        'Login': 'Log in',
        'Join': 'Join',
        'SearchAACom': 'Search AA.com®',
        'SubmitSearch' : 'Submit search',
        'SkipToGlobalNavigation': 'Skip to global navigation',
        'SkipToContent': 'Skip to content',
        'SkipToFooter': 'Skip to footer',
        'MobileNav': 'Menu link',
        'CloseMenu': 'Close menu',
        'LogInPopoverHeading': 'Have an AAdvantage® account?',
        'LogInPopoverBody': 'Log in here.',
        'TravelAlerts': 'Travel alerts',
        'HomePageLink': 'American Airlines logo link to home page'
      },
      'es': {
        'Login': 'Ingrese',
        'Join': '\u00DAnase',
        'SearchAACom': 'Buscar en aa.com',
        'SubmitSearch' : 'Enviar búsqueda',
        'SkipToGlobalNavigation': 'Pasar a la navegación',
        'SkipToContent': 'Pasar al contenido',
        'SkipToFooter': 'Pasar al pie de página',
        'MobileNav': 'Enlace del men\u00FA',
        'CloseMenu': 'Cerrar menú',
        'LogInPopoverHeading': '¿Tiene una cuenta AAdvantage®?',
        'LogInPopoverBody': 'Ingrese con su contraseña aquí.',
        'TravelAlerts': 'Alertas para su viaje',
        'HomePageLink': ' Logo de American Airlines lleva a la página de inicio'
      },
      'fr': {
        'Login': 'Connectez-vous',
        'Join': 'Rejoindre',
        'SkipToGlobalNavigation': 'Passer à la navigation générale',
        'SkipToContent': 'Passer au contenu',
        'SkipToFooter': 'Passer au bas de page',
        'MobileNav': 'Lien de menu',
        'CloseMenu': 'Fermer le menu',
        'LogInPopoverHeading': 'Avez-vous un compte AAdvantage®?',
        'LogInPopoverBody': 'Connectez-vous ici.'
      },
      'pt': {
        'Login': 'Efetuar Login',
        'Join': 'Associe-se',
        'SkipToGlobalNavigation': 'Ir para navegação global',
        'SkipToContent': 'Ir para conteúdo',
        'SkipToFooter': 'Ir para rodapé',
        'MobileNav': 'Ligação do menu',
        'CloseMenu': 'Fechar menu',
        'LogInPopoverHeading': 'Possui uma conta AAdvantage®?',
        'LogInPopoverBody': 'Faça login aqui.'
      },
      'fr_CA': {
        'SkipToGlobalNavigation': 'Passer à la navigation globale',
        'SkipToContent': 'Passer au contenu',
        'SkipToFooter': 'Passer au pied de page',
        'MobileNav': 'Lien de menu',
        'CloseMenu': 'Fermer le menu',
        'TravelAlerts': 'Alerte voyage (en anglais)',
        'HomePageLink': 'Lien vers la page d\'accueil du logo American Airlines'
      },
      'pt_BR': {
        'SkipToGlobalNavigation': 'Pular para navegação global',
        'SkipToContent': 'Pular para conteúdo',
        'SkipToFooter': 'Pular para rodapé',
        'MobileNav': 'Link do menu',
        'CloseMenu': 'Fechar menu',
        'TravelAlerts': 'Alertas de viagem',
        'HomePageLink': 'Logo da American Airlines – link para página inicial'
      },
      'it_IT': {
        'SkipToGlobalNavigation': 'Passa a navigazione globale',
        'SkipToContent': 'Passa al contenuto',
        'SkipToFooter': 'Passa a piè di pagina',
        'MobileNav': 'Link al menu',
        'CloseMenu': 'Chiudi menu'
      },
      'fi_FI': {
        'SkipToGlobalNavigation': 'Passa a navigazione globale',
        'SkipToContent': 'Passa al contenuto',
        'SkipToFooter': 'Passa a piè di pagina',
        'MobileNav': 'Link al menu',
        'CloseMenu': 'Chiudi menu'
      },
      'de_DE': {
        'SkipToGlobalNavigation': 'Zum übergeordneten Menü',
        'SkipToContent': 'Zum Inhalt',
        'SkipToFooter': 'Zur Fußzeile',
        'MobileNav': 'Menü Link',
        'CloseMenu': 'Menü schließen'
      },
      'nl_NL': {
        'SkipToGlobalNavigation': 'Verdergaan naar globale navigatie',
        'SkipToContent': 'Verdergaan naar content',
        'SkipToFooter': 'Verdergaan naar voettekst',
        'MobileNav': 'Link naar menu',
        'CloseMenu': 'Menu sluiten'
      },
      'ko_KR': {
        'SkipToGlobalNavigation': '글로벌 네비게이션으로 이동',
        'SkipToContent': '콘텐츠로 이동',
        'SkipToFooter': '바닥글로 이동',
        'MobileNav': '메뉴 링크',
        'CloseMenu': '메뉴 닫기'
      },
      'ja_JP': {
        'SkipToGlobalNavigation': 'グローバルナビゲーションへスキップ',
        'SkipToContent': 'コンテンツへスキップ',
        'SkipToFooter': 'フッターへスキップ',
        'MobileNav': 'メニューリンク',
        'CloseMenu': 'メニューを閉じる'
      },
      'zh_CN': {
        'SkipToGlobalNavigation': '跳到全球导航',
        'SkipToContent': '跳到目录',
        'SkipToFooter': '跳到页脚',
        'MobileNav': '菜单链接',
        'CloseMenu': '关闭菜单'
      },
      'zh_HK': {
        'SkipToGlobalNavigation': '跳至全球瀏覽',
        'SkipToContent': '跳至內容',
        'SkipToFooter': '跳至頁尾',
        'MobileNav': '選單連結',
        'CloseMenu': '關閉選單'
      }
    };
  }
